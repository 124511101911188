<template>
  <vx-card class="insert-receive main-box md:w-1/2 top-zero-radius">

    <div class="insert-receive-fields">
      <div class="insert-receive-field">
        <custom-validate-input :value="newDocument.user.name"
                               :classes="{'w-full': true}"
                               @input="$event !== newDocument.user.name.value ? suggestUserPromptStatus = true : ''"
                               :label="`${$t('treasury.receive.labels.user')}`"
                               align="right"/>

        <font-awesome-icon class="suggest-modal-button"
                           icon="tasks"
                           @click="suggestUserPromptStatus = true"/>

        <font-awesome-icon class="new-suggest-modal-button"
                           icon="plus"
                           @click="newSuggestUserPromptStatus = true"/>

        <!-- users List prompt -->
        <vs-prompt
          class="very-big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="suggestUserPromptStatus"
          @close="suggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('selectUsers')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.select.title')}}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="suggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content py-2 px-3">
            <template>
              <keep-alive>
                <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
              </keep-alive>
            </template>
            <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
          </div>

        </vs-prompt>

        <!-- insert new user prompt -->
        <vs-prompt
          class="prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="newSuggestUserPromptStatus"
          @close="newSuggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('insertUserBTN')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.user.insert.title') }}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="newSuggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content">
            <template>
              <keep-alive>
                <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
              </keep-alive>
            </template>
            <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
          </div>

        </vs-prompt>
      </div>

      <div class="insert-receive-field mt-3" v-if="checkUserPermissions('receive.dynamicDate')">
        <custom-date-picker-input :label="$t('treasury.receive.labels.date')"
                                  type="datetime"
                                  :input-format="$validator('moment.dateTime')"
                                  :is-empty="true"
                                  :max="currentDate"
                                  :placeholder="$t('treasury.receive.placeholder.date')"
                                  :invalid-text="$t('treasury.receive.validators.dateHelp', {date: `${currentDate.split(' ')[1]} ${currentDate.split(' ')[0]}`})"
                                  :regex="dateTimeRegex"
                                  v-model="newDocument.date" />
      </div>

      <div class="insert-receive-field mt-3"
           v-if="type === 1">
        <span class="label">{{ $t('treasury.receive.labels.cash') }}</span>
        <custom-select :default="newDocument.cash"
                       :options="cashes"
                       v-model="newDocument.cash"/>
      </div>

      <div class="insert-receive-field mt-3"
           v-if="type === 2">
        <span class="label">{{ $t('treasury.receive.labels.bank') }}</span>
        <custom-select :default="newDocument.bank"
                       :options="banks"
                       v-model="newDocument.bank"/>
      </div>

      <div class="insert-receive-field mt-3"
           v-if="type === 3">
        <span class="label">{{ $t('treasury.receive.labels.paymentGateway') }}</span>
        <custom-select :default="newDocument.paymentGateway"
                       :options="paymentGateways"
                       v-model="newDocument.paymentGateway"/>
      </div>
    </div>

    <div class="insert-receive-fields mt-3">
      <div class="insert-receive-field">
        <custom-price-input :label="`${$t('treasury.receive.labels.price')}`"
                            :classes="{'w-full': true}"
                            v-model="newDocument.price"/>
      </div>

      <div class="insert-receive-field" v-if="type === 3">
        <custom-validate-input :label="`${$t('treasury.receive.labels.transactionsReferenceId')}`"
                               :classes="{'w-full': true}"
                               :regex="$validator('regex.treasury.receive.transactionId')"
                               v-model="newDocument.transactionsReferenceId"/>
      </div>

      <div class="insert-receive-field">
        <custom-validate-textarea :label="`${$t('treasury.receive.labels.description')}`"
                                  :classes="{'w-full': true}"
                                  :rtl="true"
                                  align="right"
                                  :is-empty="false"
                                  v-model="newDocument.description"/>
      </div>
    </div>

    <!-- type selector -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title="انتخاب روش دریافت"
      @close="$router.replace({name: 'treasuryReceive'})"
      :active.sync="selectReceiveTypeStatusPrompt">

      <div class="prompt-content p-3">

        <button class="select-type-button mt-3"
                v-if="checkUserPermissions('cash.show')"
                @click="handleSelectReceiveType(1)">
          <custom-icon class="ml-2"
                       icon="MONEY"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.receive.receiveTypes.cash') }}
          </span>

          <vs-spacer/>
        </button>

        <button class="select-type-button mt-3"
                v-if="checkUserPermissions('bank.show')"
                @click="handleSelectReceiveType(2)">
          <custom-icon class="ml-2"
                       icon="CREDIT_CARD"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.receive.receiveTypes.bank') }}
          </span>

          <vs-spacer/>
        </button>

        <button class="select-type-button mt-3"
                v-if="checkUserPermissions('payment_gateway.show')"
                @click="handleSelectReceiveType(3)">
          <custom-icon class="ml-2"
                       icon="PAY"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.receive.receiveTypes.paymentGateway') }}
          </span>

          <vs-spacer/>
        </button>
      </div>
    </vs-prompt>
    <!-- /type selector -->

    <vs-button id="insertDocumentBTN" @click="insertDocument" class="useral-action-button"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment-jalaali'
import {getBanks} from '@/http/requests/banks'
import {getCashBoxes} from '@/http/requests/cashBoxes'
import {insertReceive} from '@/http/requests/documents'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import SelectUsers from '../../../customers/select/selectUsers'
import InsertUser from '../../../customers/insert/insertUser'
import {getUser} from '../../../../../http/requests/users/users'
import {getPaymentGateways} from '../../../../../http/requests/paymentGateways'
import CustomPriceInput from '../../../../../components/customInput/customPriceInput'
import CustomValidateTextarea from '../../../../../components/customInput/customValidateTextarea'
import CustomSelect from '../../../../../components/customSelect/customSelect'
import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
import {checkUserPermissions, getTimeFromServer} from "../../../../../assets/js/functions";

export default {
  name: 'insertReceipt',
  metaInfo () {
    return {
      title: this.$t('treasury.receive.insert.title')
    }
  },
  components: {
    CustomDatePickerInput,
    CustomSelect,
    InsertUser,
    SelectUsers,
    CustomIcon,
    CustomValidateInput,
    CustomValidateTextarea,
    CustomPriceInput},
  data () {
    return {
      suggestUserPromptStatus: false,
      newSuggestUserPromptStatus: false,
      selectReceiveTypeStatusPrompt: true,
      dateTimeRegex: this.$validator('regex.dateTime'),
      type: 0,
      currentDate: '',
      cashes: [],
      banks: [],
      paymentGateways: [],
      selectedPaymentGateway: {},
      newDocument: {
        date: {
          value: '',
          isValid: true
        },
        price: {},
        description: {},
        transactionsReferenceId: {},
        user: {
          name: {},
          id: 0
        },
        cash: {
          label: '-',
          value: 0
        },
        bank: {
          label: '-',
          value: 0
        },
        paymentGateway: {
          label: '-',
          value: 0
        }
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertDocumentBTN',
              color: 'success',
              icon: 'SAVE',
              iconPack: 'useral'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryReceive'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
    }, 50)
    this.currentDate = moment(getTimeFromServer()).format(this.$validator('moment.dateTime'))

    this.checkReceiveTypePermissions()

    this.getUser()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    checkReceiveTypePermissions () {
      if (checkUserPermissions('cash.show') &&
          !checkUserPermissions('bank.show') &&
          !checkUserPermissions('payment_gateway.show')) {
        this.selectReceiveTypeStatusPrompt = false
        this.handleSelectReceiveType(1)
      } else if (!checkUserPermissions('cash.show') &&
          checkUserPermissions('bank.show') &&
          !checkUserPermissions('payment_gateway.show')) {
        this.selectReceiveTypeStatusPrompt = false
        this.handleSelectReceiveType(2)
      } else if (!checkUserPermissions('cash.show') &&
          !checkUserPermissions('bank.show') &&
          checkUserPermissions('payment_gateway.show')) {
        this.selectReceiveTypeStatusPrompt = false
        this.handleSelectReceiveType(3)
      }
    },
    handleSelectReceiveType (type) {
      this.type = type
      this.selectReceiveTypeStatusPrompt = false

      switch (type) {
      case 1:
        this.getCashes()
        this.$store.dispatch('setPageTitle', this.$t('treasury.receive.receiveTypes.cash'))
        break

      case 2:
        this.getBanks()
        this.$store.dispatch('setPageTitle', this.$t('treasury.receive.receiveTypes.bank'))
        break

      case 3:
        this.getPaymentGateways()
        this.$store.dispatch('setPageTitle', this.$t('treasury.receive.receiveTypes.paymentGateway'))
        break
      }
    },
    getUser () {
      if (this.$route.query.hasOwnProperty('user')) {
        getUser(this.$route.query.user).then(response => {
          const user = response.data.data
          this.newDocument.user = {
            name: {
              value: `${user.name} ${user.family}`,
              isValid: true
            },
            id: user.id
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      }
    },
    getBanks () {
      getBanks().then(response => {
        const banks = response.data.data
        banks.forEach((bank) => {
          this.banks.push({
            label: bank.name,
            value: bank.id
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    getPaymentGateways () {
      getPaymentGateways(1, [/*'type=2'*/]).then(response => {
        const paymentGateways = response.data.data
        paymentGateways.forEach((paymentGateway) => {
          this.paymentGateways.push({
            label: paymentGateway.title,
            value: paymentGateway.id
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    getCashes () {
      getCashBoxes().then(response => {
        const cashes = response.data.data
        cashes.forEach((cash) => {
          this.cashes.push({
            label: cash.name,
            value: cash.id
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    handleNewUserInserted (user) {
      this.newDocument.user.id = user.id
      this.newDocument.user.name.value = `${user.name} ${user.family}`
      this.newSuggestUserPromptStatus = false
    },
    handleSuggestUserSelected (list) {
      let user = {}
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          user = {
            id: data.id,
            name: {
              value: data.name,
              isValid: true
            }
          }
        }
      })
      this.newDocument.user = user
      this.suggestUserPromptStatus = false
    },
    insertDocument () {
      const receive = {
        user_id: this.newDocument.user.id,
        description: this.newDocument.description.value || '',
        price: (this.newDocument.price.value || 0).toString().replaceAll(',', '')
      }

      switch (this.type) {
      case 1:
        if (this.newDocument.cash.value !== 0) receive.cash_id = this.newDocument.cash.value
        break

      case 2:
        if (this.newDocument.bank.value !== 0) receive.bank_id = this.newDocument.bank.value
        break

      case 3:
        if (this.newDocument.paymentGateway.value !== 0) {
          receive.payment_gateway_id = this.newDocument.paymentGateway.value
          receive.transaction_reference_id = this.newDocument.transactionsReferenceId.value
        }
        break
      }

      if (checkUserPermissions('receive.dynamicDate') && this.newDocument.date.isValid) {
        receive.date = this.newDocument.date.value
      }

      if (receive.user_id === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidUser'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (parseInt(receive.price) === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidPrice'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (receive.description.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidDescription'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type === 3 && !this.newDocument.transactionsReferenceId.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidTransactionsReferenceId'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type === 1 && this.newDocument.cash.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidCash'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type === 2 && this.newDocument.bank.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidBank'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.type === 3 && this.newDocument.paymentGateway.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.insert.notifications.invalidPaymentGateway'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.newDocument.date.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.receive.validators.date'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      // if (moment(this.newDocument.date.value).isAfter(this.currentDate)) {
      //   this.$vs.notify({
      //     title: this.$t('alert.error.title'),
      //     text: this.$t('treasury.receive.validators.future'),
      //     time: 2400,
      //     color: 'danger',
      //     icon: 'icon-alert-circle',
      //     iconPack: 'feather'
      //   })
      //   return false
      // }

      insertReceive(receive).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.receive.insert.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        if (this.$route.query.hasOwnProperty('user')) {
          this.$router.back()
        } else {
          this.$router.push({name: 'treasuryReceive'})
        }
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.receive.insert.notifications.insert.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.insert-receive {

  .insert-receive-fields {

    .insert-receive-field {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 35px;
        top: 5px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 5px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      span.label {
        font-size: 12px;
        position: absolute;
        padding: 0 5px;
        left: 7px;
        top: -10px;
        z-index: 12000;
      }

      .useral-custom-element-select {

        .selected {
          height: 35px;
          line-height: 35px;
          font-size: 13px;
        }

        .items {
          z-index: 12100;

          div {
            font-size: 13px;
            height: 35px;
            line-height: 35px;
          }
        }
      }
    }

    &:first-child {
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: .5rem;
      padding: 0 10px;

      span.label, .custom-validate-input .input-label {
        backdrop-filter: sepia(1);
      }
    }
  }
}
</style>
